import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { getLangTerm } from "../lang";

import WalkingIcon from "../images/svg/walking_icon.svg";
import { getLangPath } from "../utils/lang";


const RouteCards = ({routeSlugs, langData, lang}) => {

    const langPath = getLangPath(lang);

    return <ul className="route-cards">
        {routeSlugs.map((route, i) => {
            const routeLangData = langData[`${lang}-${route.slug}`];
            // debugger;
            return <li key={i} className={route.slug}>
                <div className="border-container" style={{backgroundColor: route.color}}></div>
                <Link
                    className="inner"
                    to={`${langPath}/routes/${route.slug}`}
                >
                    <img className={"route-image"} src={route.thumbnail.appsmall} alt={routeLangData.title} />
                    <div className="route-content">
                        <h1>{routeLangData.title}</h1>
                        <div className="is-premium">
                            { route.isPremium ? <><StaticImage
                                className="premium-icon"
                                src="../images/premium_gem.png"
                                quality={95}
                                formats={["auto", "webp", "avif"]}
                                alt="Premium Icon"
                                placeholder={"none"}
                            />
                            <span>{ getLangTerm("premium-route", lang) }</span></>
                                : <span>{ getLangTerm("free-route", lang) }</span> }
                        </div>
                        <div className="walking-time">
                            <WalkingIcon className="walking-icon" alt="Walking Icon" />
                            { getLangTerm("walking-time", lang) }: { route.walkingTime}
                        </div>
                        <div className="route-desc">{routeLangData.summary}</div>
                        <div className="read-more">{ getLangTerm("read-more", lang) }</div>
                    </div>
                </Link>
            </li>
        })}
    </ul>
}
export default RouteCards;