import React from "react";

import Layout from "../components/Layout";
import OfferingContent from "../components/OfferingContent";


const Offering = ({pageContext}) => {

    return (
        <Layout 
            pageContext={pageContext}
            extraClass={"offering-container"}
        >
            <OfferingContent pageContext={pageContext} />
        </Layout>
    )
};

export default Offering;