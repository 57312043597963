import React, { useEffect } from "react";

import useApi from '../../utils/useAPI';

const RepVancouverBundle = ({repID}) => {

    const { loading, data, /*error: apiError,*/ trigger } = useApi();

    useEffect(() => {
        const url = `reps/${repID}`;
        trigger(url);
    }, []);

    const renderRepContent = () => {
        if (!loading && data) {
            let header = "Welcome to VanWalks!";
            let subheader = "You've just got the inside scoop on one of the best deals in Vancouver!";
            let showCustom = false;
            data.offerings.forEach(offering => {
                if (offering.repSecondary === "offering-vancouver_bundle" && offering.showCustom) {
                    showCustom = true;
                }
            })
            if (data.name && showCustom) {
                header = `${data.name} and VanWalks`
                subheader = "are proud to present";
            }
            return <><h1>{header}</h1>
                <p className="proud">{subheader}</p>
                <h1>The Vancouver Bundle!</h1>
                <p>A sweet deal and the best way to explore Vancouver at your own pace. Includes <b>Gastown</b> and both <b>Stanley Park</b> walking routes</p>
            </>
        }
    };

    return <div className="custom-header">
        {
            renderRepContent()   
        }
    </div>
}

export default RepVancouverBundle;