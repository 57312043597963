import React from "react";

const ToonieToursOfferings = () => {
    return <div className="custom-header">
        <h1>Toonie Tours and VanWalks</h1>
        <p className="proud">are proud to present</p>
        <h1>The Vancouver Bundle!</h1>
        <p>A sweet deal and the best way to explore Vancouver at your own pace. Includes <b>Gastown</b> and both <b>Stanley Park</b> walking routes</p>
    </div>
}

export default ToonieToursOfferings;