import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";

import PurchaseButton from "../components/PurchaseButton";
import { formatPrice } from "../components/PurchaseButtons";
import RouteCards from "../components/RouteCards";
import { parseStripeData, StripeOfferings } from "../utils/stripe";

import ToonieToursOfferings from "../components/custom/ToonieTours";
import SleepyMooseOfferings from "../components/custom/SleepyMoose";
import RepVancouverBundle from "../components/custom/RepVancouverBundle";
import { getLangTerm } from "../lang";
import PoweredByStripe from "../images/svg/powered_by_stripe.svg";


const OfferingContent = ({pageContext, isEmbedded}) => {
    const { routeSlugs, langData, lang } = pageContext;

    const [productKey, setProductKey] = useState(undefined);
    const [repID, setRepID] = useState(undefined);

    useEffect(() => {
        let search = typeof window !== "undefined" && window.location.search;
        let params = new URLSearchParams(search);
        const productKeyFromQuery = params.get('productKey');
        setProductKey(productKeyFromQuery);
        const repIDFromQuery = params.get('repID');
        setRepID(repIDFromQuery);
    }, [])

    const screenshotCarousel = <Carousel
        dynamicHeight={false}
        showStatus={false}
        showThumbs={false}
    >
        { [<StaticImage
            key="1"
            className="screenshot"
            src="../images/home_screenshot_1.png"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="VanWalks Screenshot"
            objectFit="contain"
            placeholder={"none"}
        />,<StaticImage
            key="2"
            className="screenshot"
            src="../images/home_screenshot_2.png"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="VanWalks Screenshot"
            objectFit="contain"
            placeholder={"none"}
        />
        ] }
    </Carousel>

    return <div className="offering-content">
        { productKey === null && <div className="content-row offering-header">
            <div className="left sixty">
                <h1>Welcome to VanWalks!</h1>
                <p>Looks like you got to this page by mistake.</p>
                <Link to="/">Back to the VanWalks Home Page</Link>
            </div>
            <div className="right forty">
                { screenshotCarousel }
            </div>
        </div>  }
        {productKey && <StripeOfferings renderFunction={({data: stripeData}) => {

            const { targetOffering, bundledRoutes, fullPrice, savings } = parseStripeData({productKey, routeSlugs, stripeData});
            let isCustom = false;
            let customComponent = null;
            if (targetOffering?.product_key === "toonie_tours_bundle") {
                isCustom = true;
                customComponent = <ToonieToursOfferings />
            }
            if (repID == "Z8dap5PE95") {
                isCustom = true;
                customComponent = <SleepyMooseOfferings />
            }
            if (targetOffering?.product_key === "vancouver_bundle") {
                isCustom = true;
                customComponent = <RepVancouverBundle repID={repID} />
            }

            const freeRoutes = routeSlugs.filter(routeSlug => {
                return !routeSlug.isPremium;
            });

            const bundleNoun = targetOffering?.name?.toLowerCase().includes("bundle") ? "Bundle" : "Route";

            return <>
                <div className="content-row offering-header">
                    <div className="left sixty">
                        { isCustom && customComponent}
                        { !isCustom && <>
                            <h1>Welcome to VanWalks!</h1>
                            <p>You&apos;ve just got the inside scoop on one of the best deals in Vancouver!</p>
                            <h2>{ targetOffering?.name }</h2>
                        </>}
                        { !targetOffering && <p>There was an error loading your deal.</p> }
                        { targetOffering && <div className="offering-row">
                            <div className="offering-details">
                                <div className="border-container"></div>
                                <div className="inner">
                                    <h3>{ targetOffering?.name }</h3>
                                    <div className="bundle-price">
                                        {  savings > 0 ? <><span className="full-price">Regular Price: {formatPrice(fullPrice, targetOffering?.currency)}</span>
                                            <span className="bundle-price">Bundle Price: {formatPrice(targetOffering?.unit_amount, targetOffering?.currency)}</span></> :
                                            <span className="bundle-price">{bundleNoun} Price: {formatPrice(targetOffering?.unit_amount, targetOffering?.currency)}</span>
                                        }
                                    </div>
                                    <PurchaseButton priceID={targetOffering?.id} name={targetOffering?.name}>
                                        { savings > 0 ? <span>Buy Now and <span className="savings">Save { savings }%</span></span> : <span>Buy Now</span> }
                                    </PurchaseButton>
                                    <PoweredByStripe className="stripe" height="20" width="92" style={{marginTop: 10, alignSelf: "center"}}  />
                                </div>
                            </div>
                            <div className="about-vanwalks">
                                <div className="border-container"></div>
                                <div className="inner">
                                    <h3>What is VanWalks?</h3>
                                    <p className="sub-title">
                                        <span className="browser">{ getLangTerm("browser-based-web-app", lang) }</span>
                                        <span className="account">{ getLangTerm("no-app-no-accounts", lang) }</span>
                                    </p>
                                    <span className="premium-content">{ getLangTerm("unlock-premium-content-with-email", lang) }</span>
                                    <span className="languages">{ getLangTerm("languages-supported", lang) }</span>
                                </div>
                            </div>
                        </div> }
                    </div>
                    <div className="right forty">
                        { screenshotCarousel }
                    </div>
                </div>
                { !isEmbedded && <div className="offering-routes">
                    { bundledRoutes.length > 1 && <>
                        <h3>Bundle includes:</h3>
                        <RouteCards routeSlugs={bundledRoutes} langData={langData} lang={lang} />
                    </> }
                    <p>And these routes are always free:</p>
                    <RouteCards routeSlugs={freeRoutes} langData={langData} lang={lang} />
                </div>}
            </>
        }} />}
    </div>
}

export default OfferingContent;